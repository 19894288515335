html {
  scroll-behavior: smooth;
}
body {
  font-family: "Josefin Sans", sans-serif;
}
.navbar {
  padding-top: 25px;
  height: 60px;
  background-color: #323232d7;
}

#mainNav .nav-link {
  color: white;
  padding-right: 50px;
}
#mainNav .navbar-nav .nav-link:hover {
  color: red;
  border-bottom: 2px solid red;
}
#mainNav #header {
  display: flex !important;
  /*   justify-content: center !important; */
  font-size: 25px;
  color: #ffd4a9;
  padding-right: 15px;
  padding-left: 1px;
}

#header p {
  padding-top: 14px;
  padding-left: 5px;
}
#header p:hover {
  color: #df5c5c;
}
.section {
  min-height: 100%;
}
#pg {
  min-height: 100%;
  /*   overflow-y: auto; */
  background: linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)),
    url(wallpaper.jpg);
}
#Home,
#AboutUs,
#WWD,
#Events,
#Gallery,
#DonateUs,
#ContactUs {
  display: flex;
  justify-content: center;
  font-size: 25px;
  color: #ea1c2c;
  padding: 70px;
}
#Home {
  flex-direction: column;
  align-items: center;
  font-size: 25px;
  padding-top: 15%;
}
#obj {
  padding: 4.5%;
  color: white;
  font-size: 18.25px;
  padding-bottom: 20%;
}

/*-------------------------------------*/

#wp {
  min-height: 100%;
  overflow-y: auto;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(vrndvn.png);
}
#AboutUs {
  padding-top: 10%;
}

/*--------------------------------------*/
#pg3 {
  min-height: 100%;
  overflow-y: auto;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(wwd.jpg);
}
.good-deed,
.good-deeds {
  line-height: 50px;
  border-radius: 5px;
  font-size: 17.5px;
  transition-duration: 1s !important;
  padding-left: 5px;
}
/* .good-deeds {
background-color: #4b80ca7d;
} */
.good-deed {
  color: #8ab3eed7;
}
#pg3 #obj {
  padding-bottom: 10%;
}
/*------------------------------------*/
#rishikesh {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(rishikesh1.png);
}
#karthik {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(parikrama1.png);
}

.col-md-6 .img-fluid {
  position: relative;
  left: auto;
  width: 700px;
  height: 511px;
  padding: 10px;
  text-align: center;
}
#heritage{
  left:20% !important;
}
.centered {
  position: absolute;
  top: 20%;
  left: 25%;
  font-weight: bold;
  font-size: 30px;
  color: white;
}
/* 
.left {
  top: 40%;
  left: 10%;
}
 */
.right {
  right: 18%;
}

.left,
.right {
  top: 40%;
  left: 15%;
  font-weight: normal;
  position: absolute;
  font-size: 20px;
  color: #fff;
}
#learn {
  position: absolute;
  top: 75%;
  left: 35%;
  color: #faf8f1;
  background-color: #4b56d292;
  outline: #111867;
  transition: background-color 1s;
}
#learn:hover {
  background-color: #fb257692;
}

/*-------------------------------------*/
* {
  box-sizing: border-box;
}
.slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: auto;
}

.slideshow-container img {
  width: 825px;
  height: 500px;
  object-fit: cover;
  display: none;
}

.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: white;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  background-color: rgba(0, 0, 0, 0.5);
  transform: translateY(-50%);
}
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active {
  background-color: #717171;
}

.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
#motion {
  padding-bottom: 15%;
}
/*---------------------------------------*/
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7%;
  padding-top: 0px;
}

.container form {
  padding: 20px;
  width: 700px;
  background: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.container form .title {
  font-size: 20px;
  color: black;
  padding-bottom: 5px;
  text-transform: uppercase;
}

.container form .inputBox {
  margin: 15px 0;
}

.container form .inputBox span {
  margin-bottom: 10px;
  display: block;
}

.container form .inputBox input {
  width: 100%;
  border: 1px solid #ccc;
  padding: 10px 15px;
  font-size: 15px;
  text-transform: none;
}

.container form .inputBox input:focus {
  border: 1px solid #000;
}

.container form .flex {
  display: flex;
  gap: 15px;
}

.container form .inputBox {
  margin-top: 5px;
}

.container form .inputBox img {
  height: 34px;
  margin-top: 5px;
  filter: drop-shadow(0 0 1px #000);
}

.col img {
  width: 32px;
  height: 32px;
}
.inputBox label {
  display: flex;
}

.required {
  color: red;
  margin-left: 5px;
}
#upiDetail #upiId {
  width: 200px;
  height: 275px;
}
.collapsible {
  background-color: white;
  color: black;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  font-size: 15px;
  transition: background-color 0.3s ease;
}

.active,
.collapsible:hover {
  background-color: #ff1e346c;
}

.content {
  padding: 0 18px;
  overflow: hidden;
  background-color: #f1f1f1;
  z-index: 2;
  max-height: 0;
  transition: max-height 0.3s ease;
}
.content.active {
  max-height: 1000px;
}
.btn {
  font-weight: bold;
  background-color: #ea1c2c;
  color: white;
  margin: 10px;
}
#Trans {
  color: red;
}
#cardDetails,
#upiDetail,
#paypalDetail {
  transition-property: all;
  overflow-y: hidden;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
}
#donation {
  position: relative;
  width: 100%;
}
#donation .bg1,
.bg2 {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  width: 50%;
}
#donation .bg1 {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(donate.jpg);
  background-position: left center;
}
#donation .bg2 {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(donate2.jpg);
  background-position: right center;
  left: 50%;
}
#DonateUs,
.container {
  z-index: 2;
  position: relative;
}
#receipt {
  display: block;
  padding: 7px;
  margin-bottom: 0;
}
/*--------------------------------------------------*/
#contacting {
  height: 450px;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(rand2.png);
  color: white;
}
.lhs {
  position: absolute;
  right: 30%;
  left: 20%;
}
.rhs {
  position: absolute;
  left: 60%;
}
#info {
  color: red;
}
.phn {
  position: relative;
  text-align: center;
  top: 35%;
}
/*--------------------------------------------------*/
@media (max-width: 1000px) {
  #obj {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    padding: 10%;
    padding-bottom: 35%;
  }
  .navbar-brand {
    font-size: 19px !important;
    top: 0%;
    align-content: center;
  }
  #mainNav {
    padding: 0%;
  }
  /*---------------------------*/
  .good-deed,
  .good-deeds {
    font-size: 14px;
    line-height: 25px;
  }
  .good-deed {
    font-weight: bold;
  }
}
@media only screen and (min-width: 768px) {
  .bg1,
  .bg2 {
    display: block;
  }
}
@media only screen and (max-width: 767px) {
  .bg1,
  .bg2 {
    display: none;
  }
  #donation {
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      url(donate2.jpg);
    background-position: center;
    background-size: cover;
    height: auto; /* Adjust the height as needed */
  }
}
@media (max-width: 767px) {
  .nav-item {
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .navbar-collapse {
    background-color: #93242fdb;
  }
  .pg {
    margin-top: 50px; /* Adjust the margin to push other contents downward */
  }
  #Home {
    padding-top: 30%;
  }
  #obj {
    padding-bottom: 42%;
  }
  /*-------------------------------*/
  #AboutUs {
    padding-top: 15%;
  }
  #wp #obj {
    padding-bottom: 25%;
  }
  /*-------------------------*/
  .col-md-6 .img-fluid {
    display: flex;
    font-size: 14px;
    left: 0%;
  }
  .col-md-6 {
    padding: 0%;
    padding-bottom: 50px;
  }
  .col {
    flex-basis: auto;
  }
  #learn {
    top: 70%;
    left: 30%;
  }
  .centered {
    left: 20%;
    top: 15%;
  }
  .left {
    font-weight: bold;
    left: 7%;
    font-size: 15px;
    top: 45%;
  }
  .right {
    font-weight: normal;
    right: 7%;
    font-size: 15px;
    padding: 10px;
    top: 35%;
  }
  /*------------------------------------------*/
  .slideshow-container img {
    width: 412px;
    height: 250px;
    object-fit: cover;
  }
  .prev,
  .next {
    font-size: 10px;
    padding: 12px;
  }
  /*--------------------------------------------*/
  .lhs {
    font-size: 15px;
    position: absolute;
    right: 50%;
    left: 6%;
  }
  .rhs {
    font-size: 15px;
    position: absolute;
    left: 50%;
  }
  .phn {
    position: relative;
    text-align: center;
    top: 45%;
  }
  #contacting {
    height: 450px;
  }
}
